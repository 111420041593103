import React from 'react'
import { graphql } from 'gatsby'
import { ServiceProps, Service } from '@/types'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import SubHeader from '@/components/Common/SubHeader'
import Image from '@/components/Image'
// import Image from '@/components/Image'

const ServiceDetail: React.FC<ServiceProps> = ({ data }) => {
  const service: Service = data.allServicesJson.edges[0].node

  return (
    <Layout>
      <Header />
      <SubHeader title="Service Details" />
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="post_details service_details">
                <div className="post_image">
                  <a href="#" className="d-flex h-100">
                    {/* <img src="images/service/details.jpg" alt="img" className="image-fit" /> */}
                    {/* <Image name="details.jpg" classes="image-fit" />*/}
                    <Image name={service.image.split('/')[2]} classes="image-fit" />
                    {/* <img src={`../${service.image}`} alt="img" className="image-fit" /> */}
                  </a>
                </div>
                <h2 className="post_title">
                  <a href="#">{service.name}</a>
                </h2>
                <div className="blog_meta">
                  <p>{service.descriptionDetail}</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default ServiceDetail

export const data = graphql`
  query ServicesD($slug: String!) {
    allServicesJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          name
          id
          description
          icon
          descriptionDetail
          image
        }
      }
    }
  }
`
